export const ConfigMap = {
    dev: {
        apiKey: "AIzaSyBixltEEUrxDGUFboJkhmpyK2ZsixgPyrU",
        authDomain: "clozure.firebaseapp.com",
        databaseURL: "https://clozure-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "clozure",
        storageBucket: "clozure.appspot.com",
        messagingSenderId: "687409388445",
        appId: "1:687409388445:web:1a136346773cd5c337ed74",
        measurementId: "G-C2E22Y356K"
    },
    production: {
        apiKey: "AIzaSyA_vcFBV4bNjyFjIOJTOWlnj2L9KjgAqKw",
        authDomain: "clozure-production.firebaseapp.com",
        projectId: "clozure-production",
        storageBucket: "clozure-production.appspot.com",
        messagingSenderId: "800650269723",
        appId: "1:800650269723:web:a4191defcce227afc794be",
        measurementId: "G-TYFL8V03T8"
    },
    demo: {
        apiKey: "AIzaSyAxXOKnLjZNNqqgGxsefQQXPqLm178vaDo",
        authDomain: "clozure-demo.firebaseapp.com",
        projectId: "clozure-demo",
        storageBucket: "clozure-demo.appspot.com",
        messagingSenderId: "982717227107",
        appId: "1:982717227107:web:f03418689ee56bd6e59856",
        measurementId: "G-1BKHRX29FN"
    },
    testing: {
        apiKey: "AIzaSyD-HI_l7OaDCN1-lIbYJd1TGj68dFKWBBQ",
        authDomain: "clozure-testing.firebaseapp.com",
        projectId: "clozure-testing",
        storageBucket: "clozure-testing.appspot.com",
        messagingSenderId: "956442847096",
        appId: "1:956442847096:web:d9a8948e0f41a76bc94a8d",
        measurementId: "G-NSVDPZVJBR"
    },
};